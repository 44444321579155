
// Default Online Shop stripe

.online_shop_stripe.lazy_loaded {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  height: rem-calc(165);
  margin-bottom: rem-calc(15);
  background-color: #fbb90e;
  background: radial-gradient(ellipse at center, rgba(251,185,14,1) 0%,rgba(250,177,16,1) 33%,rgba(251,177,16,1) 52%,rgba(250,147,18,1) 78%,rgba(249,126,20,1) 100%);

  .decorate_container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: {
        position: center center;
        repeat: no-repeat;
      }
      background-size: cover;
    }

    &::before {
      top: 0;
      left: rem-calc(-15);
      height: rem-calc(120);
      width: rem-calc(205);

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/corner_left.png');
      }

      @at-root .webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/corner_left.webp');
      }
    }

    &::after {
      bottom: rem-calc(-8);
      right: rem-calc(-7);
      height: rem-calc(116);
      width: rem-calc(114);

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/corner_right.png');
      }

      @at-root .webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/corner_right.webp');
      }
    }
  }

  .content_container {
    @include xy-grid-container(rem-calc(1750));
    @include xy-grid;
    position: relative;
    height: 100%;
  }

  .details,
  .products {
    flex: 0 0 auto;
    width: 100%;
  }

  .details {
    text-align: center;
  }

  .logo {
    display: none;
    visibility: hidden;
  }

  .links {
    @include xy-grid;
    justify-content: center;
    margin-bottom: rem-calc(18);
  }

  .link {
    position: relative;
    padding: rem-calc(0 8);
    color: #60226a;
    line-height: 1;
    font: {
      size: rem-calc(16);
    }

    &::before {
      @include vertical-center;
      content: '';
      right: rem-calc(-3);
      height: rem-calc(6);
      width: rem-calc(6);
      margin-top: rem-calc(-2);
      background: #60226a;
      border-radius: 50%;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::before {
        display: none;
      }
    }
  }

  .link_to {
    a {
      @include button(false, #8f2bb4, #531a71, $white);
      @include border-rounded;
      width: rem-calc(165);
      margin-bottom: 0;
      padding-top: rem-calc(7);
      padding-bottom: rem-calc(5);
      background: linear-gradient(to right, #8f2bb4 0%, #531a71 100%);
      font: {
        size: rem-calc(16);
      }
    }
  }

  .products {
    text-align: center;

    img {
      width: rem-calc(232);
    }
  }

  .products_md_image {
    display: none;
    visibility: hidden;
  }

  @include breakpoint(small only) {
    .details {
      order: 2;
    }

    .products {
      order: 1;
      margin: rem-calc(-10 0 5);
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: rem-calc(30);

    .decorate_container {
      &::before {
        top: rem-calc(-5);
        left: rem-calc(-46);
        height: rem-calc(231);
        width: rem-calc(375);
      }

      &::after {
        bottom: rem-calc(-10);
        right: rem-calc(-20);
        height: rem-calc(175);
        width: rem-calc(170);
      }
    }

    .content_container {
      justify-content: center;
      align-items: center;
    }

    .details {
      @include xy-grid;
      flex: 1 1 0;
      justify-content: flex-end;
    }

    .description {
      width: rem-calc(260);
      padding-top: rem-calc(10);
    }

    .links {
      margin-bottom: rem-calc(14);
    }

    .link_to {
      a {
        width: rem-calc(215);
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(9);
      }
    }

    .products {
      width: auto;
      margin: rem-calc(-4 0 0 25);
      background: {
        position: center center;
        repeat: no-repeat;
      }

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/products_bg.png');
      }

      @at-root .webp-lossy & {
        background-image: url('pages/home_page/online_shop_stripe/default/products_bg.webp');
      }

      background-size: auto 140px;

      img {
        width: rem-calc(340);
      }
    }

    .products_image {
      display: none;
      visibility: hidden;
    }

    .products_md_image {
      display: inline-block;
      visibility: visible;
    }
  }

  @include breakpoint(large up) {
    .decorate_container {
      &::before {
        display: none;
      }
    }

    .details {
      max-width: rem-calc(630);
    }

    .logo {
      display: flex;
      align-items: center;
      visibility: visible;
      position: absolute;
      left: 0;
      height: 100%;
      padding: rem-calc(0 0 0 25);
      background: #8f259b;
      background: linear-gradient(to bottom, rgba(146,39,160,1) 0%,rgba(133,31,148,1) 94%,rgba(135,31,149,1) 99%,rgba(135,31,149,0.98) 100%);

      &::before {
        content: '';
        position: absolute;
        background: {
          position: center center;
          repeat: no-repeat;
        }
        background-size: cover;
      }

      &::before {
        top: 0;
        right: rem-calc(-375);
        height: rem-calc(231);
        width: rem-calc(375);

        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/online_shop_stripe/default/corner_left.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/online_shop_stripe/default/corner_left.webp');
        }
      }

      img {
        width: rem-calc(240);
      }
    }
  }

  @include breakpoint(xlarge up) {
    height: rem-calc(151);
    margin-bottom: rem-calc(50);

    .logo {
      justify-content: flex-end;

      &::before {
        top: rem-calc(-10);
        right: rem-calc(-433);
        height: rem-calc(280);
        width: rem-calc(433);
      }
    }

    .details {
      max-width: rem-calc(700);
    }

    .description {
      width: rem-calc(350);
    }

    .links {
      margin-bottom: rem-calc(19);
    }

    .link {
      padding: rem-calc(0 15);
      font: {
        size: rem-calc(20);
      }
    }

    .link_to {
      a {
        width: rem-calc(250);
        padding-top: rem-calc(14);
        padding-bottom: rem-calc(11);
        font: {
          size: rem-calc(18);
        }
      }
    }
  }

  @include breakpoint(xxlarge up) {
    //margin-right: rem-calc(-35);
    //margin-left: rem-calc(-35);

    .decorate_container {
      &::after {
        height: rem-calc(279);
        width: rem-calc(274);
      }
    }

    .logo {
      padding-left: rem-calc(67);

      img {
        width: auto;
      }
    }

    .details {
      max-width: rem-calc(800);
    }
  }

  @include breakpoint(1920px up) {
    width: calc(100% + 90px);
    margin-right: rem-calc(-45);
    margin-left: rem-calc(-45);

    .decorate_container {
      &::after {
        bottom: 0;
        right: 0;
      }
    }

    .logo {
      width: rem-calc(470);
    }

    .details {
      max-width: rem-calc(880);
    }

    .products {
      margin-top: rem-calc(-10);
      margin-left: rem-calc(114);

      img {
        width: auto;
      }
    }

    .products_image {
      display: inline-block;
      visibility: visible;
    }

    .products_md_image {
      display: none;
      visibility: hidden;
    }
  }
}
