.about_online_games {
  @include xy-grid-container;
  margin-bottom: rem-calc(50);

  .section_title {
    text-align: center;
    line-height: 1.2;
  }
}

@include breakpoint(xlarge up) {
  .about_online_games {
    .section_title {
      font: {
        size: rem-calc(36);
      }
    }
  }
}
