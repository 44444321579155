&.coming_soon,
&.inactive {
  .media {
    &::before {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.48);
      height: 100%;
    }

    img {
      filter: grayscale(1);
    }
  }

  .details {
    @include absolute-center;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);

    * {
      display: none;
    }

    .name {
      display: block;
      position: relative;
      text-align: center;
      line-height: 1.2;

      // color: $orange;
      font: {
        size: rem-calc(17);
      }

      white-space: nowrap;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: rem-calc(19);
        width: rem-calc(134);

        background: {
          position: 0 0;
          repeat: no-repeat;
        }

        background-size: cover;
      }
    }

    .contact {
      display: none;
    }
  }

  .book_now {
    display: none;
  }

  .brief {
    display: none;
  }

  // &.coming_winter {
  //   .details {
  //     .name {
  //       &::before {
  //         background: {
  //           image: url('pages/home_page/rooms_list/coming_winter_title.png');
  //         }
  //       }
  //     }
  //   }
  // }

  // &.coming_spring {
  //   .details {
  //     .name {
  //       &::before {
  //         background: {
  //           image: url('pages/home_page/rooms_list/coming_spring_title.png');
  //         }
  //       }
  //     }
  //   }
  // }

  // &.coming_winter,
  // &.coming_spring {
  //   .details {
  //     .name {
  //       &::before {
  //         height: rem-calc(21);
  //         width: rem-calc(225);
  //       }
  //     }
  //   }
  // }
}

&.coming_soon {
  .details {
    .name {
      padding-bottom: rem-calc(30);
      font-size: rem-calc(24);
      color: $orange;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: rem-calc(19);
        width: rem-calc(134);

        background: {
          position: 0 0;
          repeat: no-repeat;
        }

        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/rooms_list/coming_soon_title.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/rooms_list/coming_soon_title.webp');
        }

        background-size: cover;
      }
    }
  }
}

@include breakpoint(medium up) {
  &.coming_soon {
    .details {
      .name {
        padding-bottom: rem-calc(40);

        font: {
          size: rem-calc(52);
        }

        &::before {
          height: rem-calc(24);
          width: rem-calc(166);
        }
      }
    }

    // &.coming_winter,
    // &.coming_spring {
    //   .details {
    //     .name {
    //       &::before {
    //         height: rem-calc(25);
    //         width: rem-calc(271);
    //       }
    //     }
    //   }
    // }
  }
}

@include breakpoint(xxlarge up) {
  &.coming_soon {
    .details {
      .name {
        padding-bottom: rem-calc(50);

        font: {
          size: rem-calc(28);
        }

        &::before {
          height: rem-calc(40);
          width: rem-calc(274);
        }
      }
    }

    // &.coming_winter,
    // &.coming_spring {
    //   .details {
    //     .name {
    //       &::before {
    //         height: rem-calc(32);
    //         width: rem-calc(350);
    //       }
    //     }
    //   }
    // }
  }
}

// @include breakpoint(1920px up) {
//   .coming_soon {
//     &.coming_winter,
//     &.coming_spring {
//       .details {
//         .name {
//           &::before {
//             height: rem-calc(41);
//             width: rem-calc(435);
//           }
//         }
//       }
//     }
//   }
// }
