.page_overview {
  @include xy-grid-container;
  margin-bottom: rem-calc(50);

  .content_container {
    @include xy-gutters($grid-margin-gutters, padding);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    background: $white;
    border-radius: rem-calc(10);
    box-shadow: 0 0 16px 0 rgba($black, 0.16);
    color: #565656;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .section_title {
    text-align: center;
    color: $black;
    line-height: 1.2;
    color: inherit;
  }

  h1,
  .section_title {
    font: {
      size: rem-calc(18);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: rem-calc(20 0 10);
    font: {
      size: rem-calc(14);
    }
  }

  p,
  .description {
    margin-bottom: rem-calc(20);
    font: {
      size: rem-calc(14);
    }
  }
}

@include breakpoint(medium up) {
  .page_overview {
    .content_container {
      padding-top: rem-calc(35);
    }

    h1,
    .section_title {
      margin-bottom: rem-calc(15);
      font: {
        size: rem-calc(22);
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font: {
        size: rem-calc(18);
      }
    }

    p,
    .description {
      text-align: center;
      font: {
        size: rem-calc(16);
      }
    }
  }
}
