.godzilla_vs_kong_prebooking_open {
  position: relative;
  overflow: hidden;

  background: {
    color: $black;
    position: center center;
  }

  background-size: cover;

  @at-root .no-webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/godzilla_vs_kong_prebooking_open/bg-sm.png');
  }

  @at-root .webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/godzilla_vs_kong_prebooking_open/bg-sm.webp');
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title_container {
    display: block;

    img {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .title_container {
    width: rem-calc(242);
    margin-top: rem-calc(74);
  }

  .text {
    @include button(false, $orange, auto, $white);
    @include border-rounded;
    width: rem-calc(243);
    margin: rem-calc(13 0 0);

    font: {
      size: rem-calc(14);
      weight: 600;
    }

    text-shadow: none;
    text-transform: uppercase;
  }

  .note_container {
    display: none;
    visibility: visible;
  }
}

@include breakpoint(large down) {
  .godzilla_vs_kong_prebooking_open {
    .image_container {
      display: none;
      visibility: hidden;
    }
  }
}

@include breakpoint(medium up) {
  .godzilla_vs_kong_prebooking_open {
    .description {
      padding: rem-calc(50 0 0);
    }

    .title_container {
      width: rem-calc(320);
      margin-top: rem-calc(80);
    }
  }
}

@include breakpoint(xlarge up) {
  .godzilla_vs_kong_prebooking_open {
    @at-root .no-webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/godzilla_vs_kong_prebooking_open/bg.png');
    }

    @at-root .webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/godzilla_vs_kong_prebooking_open/bg.webp');
    }

    .image_container {
      position: absolute;
      top: 0;
      height: 100%;
      width: rem-calc(832);

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }

      &.godzilla {
        left: rem-calc(-400);
      }

      &.kong {
        right: rem-calc(-400);
      }
    }

    .title_container {
      width: rem-calc(460);
      margin-top: rem-calc(57);
    }

    .text {
      width: rem-calc(333);
      margin-top: rem-calc(24);

      font: {
        size: rem-calc(18);
      }
    }

    .note_container {
      @include horizontal-center;
      bottom: rem-calc(10);
      visibility: visible;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      max-width: rem-calc(1920);
      padding: rem-calc(0 20);
    }

    .note {
      text-align: right;
      color: rgba($white, 0.5);
      line-height: 1.3;

      font: {
        size: rem-calc(13);
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .godzilla_vs_kong_prebooking_open {
    .image_container {
      width: rem-calc(913);

      &.godzilla {
        left: rem-calc(-450);
      }

      &.kong {
        right: rem-calc(-450);
      }
    }

    .title_container {
      width: rem-calc(526);
      margin-top: rem-calc(68);
    }

    .subtitle_container {
      width: rem-calc(333);
      margin-top: rem-calc(34);
    }

    .note {
      margin-bottom: rem-calc(5);
    }
  }
}

@include breakpoint(1900px up) {
  .godzilla_vs_kong_prebooking_open {
    .image_container {
      &.godzilla {
        left: rem-calc(-320);
      }

      &.kong {
        right: rem-calc(-320);
      }
    }
  }
}

@include breakpoint(2560px up) {
  .godzilla_vs_kong_prebooking_open {
    .image_container {
      &.godzilla {
        left: 0;
      }

      &.kong {
        right: 0;
      }
    }
  }
}
