/// Home page - Section Team Building

.team_building.lazy_loaded {
  @include xy-grid-container(rem-calc(825));
  background: $white;
  margin-bottom: rem-calc(50);

  .section_title {
    margin: rem-calc(0 0 8 0);
    text-align: center;
    line-height: 1.2;
    font: {
      size: rem-calc(20);
    }
  }

  .description {
    margin-bottom: rem-calc(10);
    color: #565656;
    font: {
      size: rem-calc(12);
    }
  }

  .company {
    text-align: center;
    margin-bottom: rem-calc(17);

    img {
      width: 100%;
    }
  }

  .link_to_events_page {
    text-align: center;

    a {
      @include orange_border_button;
    }
  }
}

@include breakpoint(medium up) {
  .team_building.lazy_loaded {
    .section_title {
      margin-bottom: rem-calc(15);
      font: {
        size: rem-calc(30);
      }
    }

    .description {
      margin-bottom: rem-calc(15);
      text-align: center;
      font: {
        size: rem-calc(16);
      }
    }

    .company {
      margin-bottom: rem-calc(38);
    }
  }
}

@include breakpoint(xlarge up) {
  .team_building.lazy_loaded {
    margin-bottom: rem-calc(100);
  }
}
