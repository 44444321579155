/// Rooms list
.rooms_list {
  @import 'room_in_list';
  @import 'rooms_list/rooms_stub';
}

/// Layout
.rooms_list {
  @include xy-grid;
  @include xy-grid-layout(2, '.room', true, rem-calc(10));

  .room {
    margin-bottom: rem-calc(20);
  }
}

@include breakpoint(medium up) {
  .rooms_list {
    @include xy-grid-layout(2, '.room', true, rem-calc(30));

    .room {
      margin-bottom: rem-calc(30);
    }
  }
}

@include breakpoint(xlarge up) {
  .rooms_list {
    @include xy-grid-layout(3, '.room', true, rem-calc(50));

    .room {
      margin-bottom: rem-calc(50);
    }
  }
}
