.repainting_header_page .layout_header {
  background: transparent;
  border-bottom: 1px solid transparent;

  &.repainted {
    background: $white;
    border-bottom-color: #d5d5d5;
  }

  .top_bar {
    div.logo a.logo {
      background-image: url('layout/logos/logo_white.svg');
    }

    ul.pages_nav {
      a {
        color: $white;
      }
    }

    div.sign_in {

      a.signin_button,
      a.customer_name {
        color: $white;

        &::before {
          background-color: $white;
        }
      }
    }

    div.open_menu_button {
      button.open_menu_button {
        color: $white;
      }
    }
  }

  &.repainted {

    /// Top Bar Home page repainted
    .top_bar {
      div.logo a.logo {
        background-image: url('layout/logos/logo_black.svg');
      }

      ul.pages_nav {
        a {
          color: $black;
        }
      }

      div.sign_in {

        a.signin_button,
        a.customer_name {
          color: $black;

          &:before {
            background-color: $black;
          }
        }
      }

      div.open_menu_button {
        button.open_menu_button {
          color: $black;
        }
      }
    }
  }
}

.repainting_header_page .layout_header:not(.repainted) {
  .top_bar {
    .tripadvisor {
      svg {
        --variable-color: #FFF
      }
    }
  }
}
