.rooms_list_container {
  overflow: hidden;
  position: relative;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);

  background: {
    color: #252525;
  }

  background-size: 200px 200px;

  // background-color: #1b1b1b;
}

@include breakpoint(medium up) {
  .rooms_list_container {
    padding-top: rem-calc(27);
    // padding-right: rem-calc(10);
    // padding-left: rem-calc(10);
  }
}

@include breakpoint(xlarge up) {
  .rooms_list_container {
    padding-top: rem-calc(40);
  }
}

@include breakpoint(1920px up) {
  .rooms_list_container {
    padding-right: rem-calc(45);
    padding-left: rem-calc(45);
  }
}
