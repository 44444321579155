/// Link to Schedule page if Rooms list have free space

.room_stub {
  display: none;
  visibility: hidden;
  img {
    width: 100%;
  }
}

&.free_space {
  .room_stub {
    display: block;
    visibility: visible;
  }
}

.room_stub {
  .link_to_schedule {
    position: relative;
    display: block;
    box-shadow: 0 0 0 1px rgba($orange, 1);
    border-radius: inherit;
  }

  .image {
    border-radius: inherit;
  }

  .title {
    @include absolute-center;
    padding-right: rem-calc(18);
    line-height: 1;
    font: {
      size: rem-calc(14);
    }
    white-space: nowrap;

    .icon {
      @include vertical-center;
      right: 0;
      height: rem-calc(18);
      width: rem-calc(18);
    }
  }
}

@include breakpoint(medium up) {
  .room_stub {
    .title {
      padding-right: rem-calc(24);
      font: {
        size: rem-calc(20);
      }

      .icon {
        height: rem-calc(24);
        width: rem-calc(24);
      }
    }
  }
}
