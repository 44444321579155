.titanic {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;

  background: {
    color: #083056;
    position: center center;
  }

  white-space: normal;

  @at-root .no-webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/titanic/bg-sm.png');
  }

  @at-root .webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/titanic/bg-sm.webp');
  }

  .title-container {
    display: block;
    width: rem-calc(261);
  }

  .title {
    width: 100%;
    margin-bottom: 0;
  }

  .text {
    line-height: 1;

    font: {
      size: rem-calc(18);
      weight: 800;
    }
  }
}

@include breakpoint(medium up) {
  .titanic {
    .content_container {
      justify-content: center;
    }

    .description {
      width: 50%;
      padding-top: rem-calc(50);
      padding-left: 0;
      text-align: center;
    }

    .title-container {
      margin: 0 auto;
    }

    .text {
      width: 100%;
      max-width: 100%;
    }
  }
}

@include breakpoint(xlarge up) {
  .titanic {
    @at-root .no-webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/titanic/bg.png');
    }

    @at-root .webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/titanic/bg.webp');
    }

    .content_container {
      justify-content: flex-end;
    }

    .description {
      padding-top: rem-calc(100);
    }

    .title-container {
      width: rem-calc(442);
      margin-bottom: rem-calc(9);
    }

    .text {
      font: {
        size: rem-calc(26);
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .titanic {
    .description {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: rem-calc(124);
    }

    .text-container {
      margin-left: rem-calc(84);
    }
  }
}
