/// Promotions Slider on Home page

/// Base styles for slider
.promotions_slider {
  @include slider_controls;
  position: relative;
  height: rem-calc(335);
  min-height: rem-calc(335);
  background: #1b1b1b;

  @at-root .promotions_slider.slider--dragging .slider__slide {
    cursor: grabbing;
  }

  .slider__track {
    overflow: hidden;
    height: 100%;
    line-height: 0;
  }

  .slider__slide {
    overflow: hidden;
    position: relative;
    height: rem-calc(335);
    width: 100%;
    background: {
      position: center center;
      repeat: no-repeat;
    }
    background-size: cover;
    cursor: grab;
  }

  img {
    -webkit-user-drag: none;
  }

  .content_container {
    @include xy-grid-container;
    @include xy-grid;
    align-items: flex-start;
    position: relative;
    height: 100%;
  }

  .description {
    position: relative;
    z-index: 1;
    padding-top: rem-calc(50);
    text-align: center;
    text-shadow: 1px 1px 0 #333;
  }

  .title {
    color: $orange;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font: {
      size: rem-calc(22);
      weight: 700;
    }
  }

  .text {
    color: $white;
    line-height: 1.3;
    font: {
      size: rem-calc(14);
    }
  }
}

@include breakpoint(small only) {
  .promotions_slider {
    .slider__arrows {
      display: none;
      visibility: hidden;
    }

    .content_container {
      justify-content: center;
      padding-left: rem-calc(10);
      padding-right: rem-calc(10);
    }

    .description {
      max-width: rem-calc(360);
    }

    .title {
      margin-bottom: rem-calc(3);
    }
  }
}

@include breakpoint(medium up) {
  .promotions_slider {
    .description {
      width: 100%;
      padding-top: rem-calc(130);
      padding-left: rem-calc(50);
      text-align: left;
    }

    .title {
      font: {
        size: rem-calc(24);
      }
    }

    .text {
      width: 60%;
      max-width: rem-calc(570);
      line-height: 1.5;
      font: {
        size: rem-calc(16);
      }
    }
  }
}

@include breakpoint(large up) {
  .promotions_slider {
    .title {
      font: {
        size: rem-calc(34);
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .promotions_slider {
    height: rem-calc(360);

    .slider__slide {
      height: rem-calc(360);
    }
  }
}

@include breakpoint(1920px up) {
  .promotions_slider {
    .description {
      padding-top: rem-calc(145);
      padding-left: 0;
    }

    .title {
      font: {
        size: rem-calc(48);
      }
    }
  }
}

// @include breakpoint(2200px up) {
//   .promotions_slider {
//     height: rem-calc(480);

//     .slider__slide {
//       height: rem-calc(480);
//     }
//   }
// }
