/// Room media (image and video teaser)

.media {
  position: relative;
  display: block;
  min-height: rem-calc(105);
  background-color: $black;
  border-radius: rem-calc(5);
  line-height: 0;

  picture,
  picture img,
  video {
    width: 100%;
    border-radius: inherit;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease 0s;

    &.playing {
      opacity: 1;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  &::before {
    bottom: 0;
    height: 55%;
    background: linear-gradient(to bottom,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0) 23%,
                  rgba(0, 0, 0, 0) 24%,
                  rgba(0, 0, 0, 0.15) 49%,
                  rgba(0, 0, 0, 0.4) 70%,
                  rgba(0, 0, 0, 0.41) 71%,
                  rgba(0, 0, 0, 0.65) 85%,
                  rgba(0, 0, 0, 1) 100%);
    border-radius: rem-calc(0 0 5 5);
  }

  &::after {
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    border-radius: inherit;
    transition: opacity 0.3s ease 0s;
  }

  &:hover,
  &:focus {
    &::after {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

@include breakpoint(medium up) {
  .media {
    min-height: rem-calc(241);
  }
}

@include breakpoint(xxlarge up) {
  .media {
    min-height: rem-calc(285);
  }
}

@include breakpoint(1920px up) {
  .media {
    min-height: rem-calc(394);
  }
}
