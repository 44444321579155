/// 2.2. Brief (level, time, players_count_range)

.brief {
  @include xy-grid;
  position: absolute;
  top: 0;
  left: 0;
  padding: rem-calc(10);
  color: $white;
  line-height: 1;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.3s ease 0s;

  .level,
  .time,
  .players_count_range {
    @include xy-cell-base(shrink);
    position: relative;
    margin-right: rem-calc(25);
    padding-left: rem-calc(25);
    font: {
      size: rem-calc(16);
    }

    .icon {
      @include vertical-center;
      left: 0;
      height: rem-calc(16);
      width: rem-calc(16);
      margin-top: rem-calc(-2);
      fill: $orange;
    }
  }

  .level {
    .icon {
      height: rem-calc(18);
      width: rem-calc(18);
    }
  }

  .players_count_range {
    .icon {
      height: rem-calc(15);
      width: rem-calc(15);
    }
  }
}

&:hover,
&:focus,
&.video_playing {
  .brief {
    opacity: 1;
  }
}

@include breakpoint(small only) {
  .brief {
    display: none;
    visibility: hidden;
  }
}

@include breakpoint(xxlarge up) {
  .brief {
    padding: rem-calc(25 20);
  }
}
