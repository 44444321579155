
.promotions_banner {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  transform: translateY(0);
  backface-visibility: hidden;

  &.is_animating {
    transition-property: transform, opacity;
    transition: transform 0.3s ease 0s;
  }

  .control_button {
    @extend %with_cursor_without_outline;
    position: relative;
    display: inline-block;
    margin-left: rem-calc(15);
    padding: rem-calc(10 20 7 6);
    border-radius: rem-calc(5 5 0 0);
    line-height: 1;
    font: {
      size: rem-calc(12);
    }
    text-transform: uppercase;

    .icon {
      @include vertical-center;
      right: rem-calc(7);
      height: rem-calc(12);
      width: rem-calc(12);
      transition: opacity 0.3s ease-in-out;
    }

    .caret_down {
      opacity: 1;
    }

    .caret_up {
      opacity: 0;
    }
  }

  .content_container {
    height: rem-calc(72);
  }

  .inner_container {
    @include xy-grid;
    height: 100%;
  }

  .image_container {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
  }

  &.collapsed {
    transform: translateY(70px);

    .control_button {
      .caret_down {
        opacity: 0;
      }

      .caret_up {
        opacity: 1;
      }
    }

    .image_container {
      visibility: hidden;
      opacity: 0;
    }
  }
}

@include breakpoint(small only) {
  .promotions_banner {
    .content_container {
      height: rem-calc(85);
    }

    .code {
      position: relative;
      display: inline-block;
      padding: rem-calc(6 10 4 10);
      text-align: center;
      font: {
        size: rem-calc(14);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .inner_text {
        position: relative;
      }
    }

    &.collapsed {
      transform: translateY(83px);
    }
  }
}

@include breakpoint(medium up) {
  .promotions_banner {
    .control_button {
      margin-left: rem-calc(15);
      padding: rem-calc(13 35 9 12);
      font: {
        size: rem-calc(14);
      }

      .icon {
        right: rem-calc(18);
      }
    }

    .content_container {
      height: rem-calc(102);
    }

    &.collapsed {
      transform: translateY(100px);
    }
  }
}

@include breakpoint(large up) {
  .promotions_banner {
    .content_container {
      height: rem-calc(72);
    }

    &.collapsed {
      transform: translateY(70px);
    }
  }
}

@include breakpoint(xlarge up) {
  .promotions_banner {
    .control_button {
      margin-left: rem-calc(25);
    }
  }
}

@include breakpoint(1920px up) {
  .promotions_banner {
    .control_button {
      margin-left: rem-calc(70);
    }
  }
}
