@mixin higlight_label {
  position: relative;
  min-width: rem-calc(110);
  padding: rem-calc(10 10 7);
  background: none;
  text-align: center;
  color: $black;
  font: {
    size: rem-calc(13);
    weight: 700;
  }
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: skew(-20deg);
    background: $white;
  }

  .inner_text {
    position: relative;
  }

  &.discounted_room_label {
    color: $white;

    &::before {
      background: #f70400;
    }
  }

  &.featured_room_label {
    color: $white;

    &::before {
      background: #7900ff;
    }
  }

  &.new_room_label {
    color: $white;

    &::before {
      background: #29a7f3;
    }
  }
}
