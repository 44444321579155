.events.lazy_loaded {
  position: relative;
  margin-bottom: rem-calc(60);
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(90);

  background: {
    position: 0 0;
    repeat: repeat;
  }

  @at-root .no-webp-lossy & {
    background-image: url('pages/home_page/events/bg.jpg');
  }

  @at-root .webp-lossy & {
    background-image: url('pages/home_page/events/bg.webp');
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: {
      position: center bottom;
      repeat: no-repeat;
    }

    @at-root .no-webp-lossy & {
      background-image: url('pages/home_page/events/bottom_corner_sm.jpg');
    }

    @at-root .webp-lossy & {
      background-image: url('pages/home_page/events/bottom_corner_sm.webp');
    }
  }

  .content {
    @include xy-grid-container(rem-calc(1350));
    position: relative;
    z-index: 1;
  }

  .section_header {
    margin-bottom: rem-calc(20);
    text-align: center;
    line-height: 1;
  }

  .description {
    margin-bottom: rem-calc(15);
    text-align: center;
    color: #565656;

    font: {
      size: rem-calc(14);
    }
  }

  .link_to_events_page {
    text-align: center;

    a {
      @include orange_border_button;
      width: rem-calc(220);
      padding: rem-calc(18 0 12);

      font: {
        size: rem-calc(16);
      }
    }
  }
}

.events_slider {
  margin-bottom: rem-calc(15);
}

.event {
  width: rem-calc(236);
  margin-bottom: rem-calc(25);
  // padding: rem-calc(0 5);

  a {
    position: relative;
    display: block;

  }

  .image {
    display: block;
    position: relative;
    border-radius: rem-calc(7);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: rem-calc(120);
      width: 100%;
      background: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.01) 0%,
          rgba(0, 0, 0, 0.76) 89%,
          rgba(0, 0, 0, 0.76) 100%);
      border-radius: rem-calc(0 0 7 7);
    }
  }

  img {
    width: 100%;
    border-radius: rem-calc(7);
  }

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: rem-calc(15);
    text-align: center;
    color: $white;
    line-height: 1;

    font: {
      size: rem-calc(18);
    }
  }
}

@include breakpoint(large down) {
  .events.lazy_loaded {
    .slider__slides {
      cursor: grab;
    }

    @at-root .events_slider.slider--dragging .event a {
      cursor: grabbing;
    }
  }
}

@include breakpoint(medium up) {
  .events.lazy_loaded {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(114);

    .section_header {
      margin-bottom: rem-calc(10);

      font: {
        size: rem-calc(46);
      }
    }

    .description {
      margin-bottom: rem-calc(40);

      font: {
        size: rem-calc(16);
      }
    }
  }

  .events_slider {
    margin-bottom: rem-calc(30);
  }
}

@include breakpoint(769px up) {
  .events.lazy_loaded {
    &::after {
      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/events/bottom_corner_lg.jpg');
      }

      @at-root .webp-lossy & {
        background: {
          image: url('pages/home_page/events/bottom_corner_lg.webp');
        }
      }
    }
  }
}

@include breakpoint(xlarge only) {
  .events.lazy_loaded {
    .content {
      padding: 0;
    }
  }
}

@include breakpoint(xlarge up) {
  .events.lazy_loaded {
    margin-bottom: rem-calc(100);

    .description {
      margin-bottom: rem-calc(20);
    }
  }

  .event {
    margin-right: rem-calc(10);
    margin-left: rem-calc(10);
    padding: 0;
  }
}

@include breakpoint(1921px up) {
  .events.lazy_loaded {
    &::after {
      height: rem-calc(285);

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/events/bottom_corner.jpg');
      }

      @at-root .webp-lossy & {
        background: {
          image: url('pages/home_page/events/bottom_corner.webp');
        }
      }

      background-size: cover;
    }
  }
}
