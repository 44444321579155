/// Room in list details (room name, contact info, book now button)

.details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: rem-calc(3 5);
  z-index: 3;
}

.name {
  display: block;
  line-height: 1.3;

  font: {
    size: rem-calc(14);
  }

  text-shadow: 1px 1px 0 #333;
}

.location-name {
  position: relative;
  display: block;
  padding-left: rem-calc(15);
  color: $white;

  font: {
    size: rem-calc(12);
  }

  .icon {
    @include vertical-center;
    left: 0;
    height: rem-calc(12);
    width: rem-calc(12);
    fill: $white;
  }
}

.contact {
  display: block;
}

.address,
.phone {
  position: relative;
  display: block;
  padding-left: rem-calc(12);
  color: $white;
  line-height: 1.1;

  font: {
    size: rem-calc(11);
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    height: rem-calc(12);
    width: rem-calc(12);
    fill: $orange;
  }
}

.address {
  margin-bottom: rem-calc(3);
}

.phone::before {
  transform: scaleX(-1);
}

.book_now {
  @include button(false, transparent, $orange, $orange);
  @include border-rounded;
  position: absolute;
  bottom: rem-calc(10);
  right: rem-calc(10);
  width: rem-calc(120);
  margin: 0;
  padding-top: rem-calc(11);
  padding-bottom: rem-calc(8);
  border-color: $orange;
  z-index: 3;

  font: {
    size: rem-calc(14);
  }

  &:hover,
  &:focus {
    color: $black;
  }
}

@include breakpoint(small only) {
  .contact {
    display: none;
    visibility: hidden;
  }

  .book_now {
    display: none;
    visibility: hidden;
  }
}

@include breakpoint(medium up) {
  .details {
    padding: rem-calc(0 10 10);
  }

  .name {
    margin-bottom: rem-calc(5);

    font: {
      size: rem-calc(18);
      weight: 700;
    }

    text-transform: uppercase;
  }

  .location-name {
    display: none;
    visibility: hidden;
  }

  .address,
  .phone {
    padding-left: rem-calc(18);

    font: {
      size: rem-calc(13);
    }
  }

  .address {
    margin-bottom: rem-calc(5);
  }
}

@include breakpoint(xxlarge up) {
  .details {
    padding: rem-calc(0 20 15);
  }

  .name {
    font: {
      size: rem-calc(24);
    }
  }

  .address,
  .phone {
    padding-left: rem-calc(23);

    font: {
      size: rem-calc(16);
    }

    .icon {
      height: rem-calc(16);
      width: rem-calc(16);
    }
  }

  .address {
    margin-bottom: rem-calc(8);
  }

  .book_now {
    bottom: rem-calc(15);
    right: rem-calc(20);
    width: rem-calc(155);
    padding-top: rem-calc(13);
    padding-bottom: rem-calc(9);

    font: {
      size: rem-calc(20);
    }
  }
}

@include breakpoint(1920px up) {
  .name {
    font: {
      size: rem-calc(28);
    }
  }

  .book_now {
    width: rem-calc(185);
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(11);
  }
}
