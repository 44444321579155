/// Slick slider

/// 1.   Slick controls (previous and next slide arrows)
/// 2.   Slick dots

@mixin slider_controls_base {
  .slider__arrow {
    z-index: 1;
    @extend %with_cursor_without_outline;
  }

    // slider--slider class on the .some_slider means initialized
  *[data-glide-el="controls"] {
    display: none;
  }

  &.slider--slider *[data-glide-el=controls],
  &.slider--carousel *[data-glide-el=controls] {
    display: block;
  }
}

@mixin square_slider_controls($height, $width, $background, $chevron_color, $chevron_size) {
  @include slider_controls_base;

  .slider__arrow {
    position: relative;
    height: rem-calc($height);
    width: rem-calc($width);
    background: $background;
    border-radius: rem-calc(5);
    box-shadow: 0 0 7px 0 rgba($black, 0.5);

    &:before {
      @include absolute-center;
      content: '';
      height: $chevron_size;
      width: $chevron_size - 3px;
      background-color: $chevron-color;

      font: {
        size: rem-calc($chevron_size);
      }
    }

    &.slider__arrow--prev {
      &:before {
        mask: url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMjAgMzIiPgo8dGl0bGU+Y2hldnJvbi1sZWZ0PC90aXRsZT4KPHBhdGggZD0iTTIuMTU3IDE0LjkzOWwxMi4xNDctMTIuMTQ2YzAuNTg2LTAuNTg2IDEuNTM2LTAuNTg2IDIuMTIxIDBsMS40MTcgMS40MTdjMC41ODUgMC41ODUgMC41ODYgMS41MzIgMC4wMDIgMi4xMTlsLTkuNjI3IDkuNjcxIDkuNjI2IDkuNjcyYzAuNTg0IDAuNTg2IDAuNTgzIDEuNTM0LTAuMDAzIDIuMTE5bC0xLjQxNyAxLjQxN2MtMC41ODYgMC41ODYtMS41MzYgMC41ODYtMi4xMjEgMGwtMTIuMTQ2LTEyLjE0N2MtMC41ODYtMC41ODYtMC41ODYtMS41MzYgMC0yLjEyMXoiPjwvcGF0aD4KPC9zdmc+Cg==') no-repeat center / cover;
      }
    }

    &.slider__arrow--next {
      &:before {
        mask: url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMjAgMzIiPgo8dGl0bGU+Y2hldnJvbi1yaWdodDwvdGl0bGU+CjxwYXRoIGQ9Ik0xNy44NDIgMTcuMDYxbC0xMi4xNDYgMTIuMTQ2Yy0wLjU4NiAwLjU4Ni0xLjUzNiAwLjU4Ni0yLjEyMSAwbC0xLjQxNy0xLjQxN2MtMC41ODUtMC41ODUtMC41ODYtMS41MzMtMC4wMDItMi4xMTlsOS42MjYtOS42NzItOS42MjYtOS42NzJjLTAuNTgzLTAuNTg2LTAuNTgyLTEuNTM0IDAuMDAyLTIuMTE5bDEuNDE3LTEuNDE3YzAuNTg2LTAuNTg2IDEuNTM2LTAuNTg2IDIuMTIxIDBsMTIuMTQ2IDEyLjE0NmMwLjU4NiAwLjU4NiAwLjU4NiAxLjUzNSAwIDIuMTIxeiI+PC9wYXRoPgo8L3N2Zz4K') no-repeat center / cover;
      }
    }

    &.slider__disabled {
      cursor: default;

      &:before {
        opacity: 0.5;
      }
    }
  }
}

/// 1.1. Slick controls (previous and next slide arrows)
@mixin slider_controls {
  @include slider_controls_base();

  .slider__arrow {
    @include vertical-center;
    z-index: 11;
    width: rem-calc(50);
    height: rem-calc(50);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white;
      border-radius: 50%;
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
      transform: scale(0.9);
    }

    .icon-wrap {
      position: relative;
      display: block;
      width: 80%;
      height: 80%;
      margin: 10%;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 25%;
        height: 50%;
        width: rem-calc(3);
        background: #fff;
        transition: transform 0.3s, background-color 0.3s;
        backface-visibility: hidden;
      }

      &:before {
        transform: translateX(-50%) rotate(30deg);
        transform-origin: 0 100%;
      }

      &:after {
        top: 50%;
        transform: translateX(-50%) rotate(-30deg);
        transform-origin: 0 0;
      }
    }

    &.slider__arrow--next {
      .icon-wrap {
        transform: rotate(180deg);
      }
    }

    &:hover {
      &:before {
        opacity: 1;
        transform: scale(1);
      }

      .icon-wrap {
        &:before,
        &:after {
          background: $black;
        }

        &:before {
          transform: translateX(-50%) rotate(45deg);
        }

        &:after {
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }

    &.slider__arrow--prev {
      left: rem-calc(13);
    }

    &.slider__arrow--next {
      right: rem-calc(13);
    }

    @include breakpoint(xlarge up) {
      &.slider__arrow--prev {
        left: rem-calc(18);
      }

      &.slider__arrow--next {
        right: rem-calc(18);
      }
    }
  }
}

/// 2. Slick dots
@mixin slider_dots_control {

  .slider__dots_control {
    position: absolute;
    bottom: rem-calc(7);
    right: rem-calc(10);
    z-index: 11;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;
      height: rem-calc(7);
      width: rem-calc(7);
      margin: rem-calc(0 0 4 0);
      background: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      color: transparent;
      cursor: pointer;
      outline: none;
    }

    li.slider__bullet--active {
      background: #fff;
    }
  }
}
