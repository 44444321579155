.promotions_slider {
  &.slider--swipeable {
    .slider_slide {
      cursor: grab;
    }
  }

  &.slider--dragging {
    .slider_slide {
      cursor: grabbing;
    }
  }

  .slider__slides {
    height: 100%;
  }

  .slider_slide {
    @include xy-cell-base;
    width: 100%;
    white-space: normal;

    @at-root .no-js & {
      min-width: 100%;
    }
  }
}
