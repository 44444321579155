/// Promotion label (use in rooms list and in room page)
.promotion_label {
  display: none;
  position: absolute;
  top: rem-calc(10);
  right: rem-calc(10);
  z-index: 1;
  height: rem-calc(44);
  width: rem-calc(47);
  background: {
    image: url('components/promotions_labels/heart/heart.png');
    position: center center;
    repeat: no-repeat;
  }
  background-size: cover;

  @include breakpoint(1920px up) {
    height: rem-calc(53);
    width: rem-calc(58);
  }
}

/// Чтобы активировать - укажите id комнат, которые учавствуют в акции
