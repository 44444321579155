/// Room in list

.room {
  position: relative;
  border-radius: rem-calc(5);

  .media_and_details {
    position: relative;
    display: block;
  }

  @import 'room_in_list/brief';
  @import 'room_in_list/details';
  @import 'room_in_list/higlight_labels';
  @import 'room_in_list/media';
  @import 'room_in_list/coming_soon';
}

@include breakpoint(small only) {
  .room {
    box-shadow: 0 0 0 1px rgba($white, 0.55);
  }
}
