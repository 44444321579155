.categories {
  background: #252525;
  border-bottom: 1px solid rgba($white, 0.2);
  line-height: 1;

  font: {
    size: rem-calc(14);
  }

  .expand_categories_list_button {
    @extend %with_cursor_without_outline;
    display: none;
  }

  .categories_list {
    @include xy-grid;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      line-height: 1;
    }

    a {
      display: block;
      padding: rem-calc(16 10 11);
      color: #a3a3a3;

      &:hover,
      &:focus {
        color: $orange;
      }
    }

    .label {
      position: relative;
      display: inline-block;
      margin-left: rem-calc(5);
      padding: rem-calc(5 5 3);
      background: none;
      color: $white;

      font: {
        size: rem-calc(14);
        weight: 700;
      }

      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #f70400;
        transform: skew(-20deg);
      }

      .fix_wrap {
        position: relative;
      }
    }
  }
}

@include breakpoint(small only) {
  .categories {
    .expand_categories_list_button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem-calc(41);
      width: 100%;
      padding: rem-calc(14 15 11);
      text-align: center;
      color: $orange;
      line-height: 1;

      font: {
        size: rem-calc(16);
      }

      .icon {
        height: rem-calc(16);
        width: rem-calc(16);
        margin-left: rem-calc(5);
        fill: currentColor;
      }

      &[aria-expanded="true"] {
        .icon {
          transform: scaleY(-1);
        }
      }
    }

    .categories_list_container {
      @include dropdown-container;
      z-index: 101;
      width: 100%;
      padding: rem-calc(4 0 12);
      background: rgba(37, 37, 37, 0.9);
      border: 0;
      border-radius: rem-calc(0 0 10 10);
    }

    .categories_list {
      li {
        @include xy-cell-base;
        width: 100%;
        text-align: center;

        &.active {
          display: none;
          visibility: hidden;
        }
      }

      a {
        padding: rem-calc(8 15);
        color: $white;
      }
    }
  }
}

@include breakpoint(medium up) {
  .categories {
    .categories_list_container {
      top: 0 !important;
      left: 0 !important;
    }

    .categories_list {
      @include xy-grid-container(rem-calc(1295));
      justify-content: space-between;

      .active a {
        color: $orange;

        &::before {
          opacity: 1;
        }
      }

      a {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: rem-calc(-1);
          left: 0;
          width: 100%;
          height: rem-calc(2);
          background: $orange;
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
        }
      }

      a:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .categories {
    font: {
      size: rem-calc(16);
    }

    .categories_list {
      a {
        padding: rem-calc(23 13 16);
      }
    }
  }
}
