/// newsletter_and_community

.newsletter_and_community.lazy_loaded {
  @include grid-column-gutter;
  background: $white;
  margin-bottom: rem-calc(50);

  .content {
    @include xy-grid-container;
    @include xy-grid;
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(25);
    background: $white;
    border-radius: rem-calc(10);
    box-shadow: 0 0 16px 0 rgba($black, 0.16);
  }

  .section_title,
  .description,
  .newsletter,
  .community {
    flex: 0 0 auto;
    width: 100%;
  }

  .section_title {
    margin-bottom: rem-calc(8);
    text-align: center;
    line-height: 1.2;
    font: {
      size: rem-calc(20);
      weight: 700;
    }
  }

  .description {
    margin-bottom: rem-calc(20);
    color: #565656;
    font: {
      size: rem-calc(12);
    }
  }

  .community {
    @include xy-grid;

    .title {
      margin-bottom: rem-calc(10);
      color: #565656;
      line-height: 1;
      font: {
        size: rem-calc(14);
        weight: 700;
      }
    }

    .news {
      flex: 0 0 auto;
      width: 100%;
      margin-top: rem-calc(10);
      line-height: 1.2;

      a {
        line-height: rem-calc(14);
        font: {
          size: rem-calc(12);
        }
      }
    }
  }

  .blog {
    flex: 0 0 auto;
    width: 50%;

    .blog_logo {
      svg {
        height: rem-calc(28);
        width: rem-calc(88);
      }
    }
  }

  .social_networks {
    @include xy-grid;
    flex: 0 0 auto;
    width: 50%;
    margin: 0;
    list-style: none;

    li {
      flex: 0 0 auto;

      &.title {
        width: 100%;
      }

      &.icon {
        height: rem-calc(27);
        width: rem-calc(27);
        margin-right: rem-calc(16);
        text-align: center;

        a {
          position: relative;
          display: block;
          height: 100%;
          border-radius: 50%;
        }
      }

      .svg_icon {
        @include absolute-center;
        height: rem-calc(16);
        width: rem-calc(16);
        fill: $white;
      }

      .instagram {
        background: #d8297f;
      }

      .facebook {
        background: #385291;
      }

      .twitter {
        background: #00b5f4;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .newsletter {
    margin-bottom: rem-calc(30);
  }

  .subscribe-to-newsletter {
    &__input-group {
      max-width: 100%;
    }

    &__input-field {
      @include border-rounded;
      padding-right: rem-calc(110);
      background: $white;
      border-color: $medium-gray;
    }

    .button {
      @include vertical-center;
      @include border-rounded;
      right: rem-calc(3);
      height: rem-calc(36);
      width: rem-calc(105);
      margin-left: 0;
      color: $black;

      &__text {
        display: block;
        visibility: visible;
      }

      &__icon {
        display: none;
        visibility: hidden;
      }
    }
  }
}

@include breakpoint(medium up) {
  .newsletter_and_community.lazy_loaded {
    .section_title {
      margin-bottom: rem-calc(15);
      font: {
        size: rem-calc(30);
      }
    }

    .description {
      text-align: center;
      font: {
        size: rem-calc(16);
      }
    }

    .newsletter {
      width: 37%;
    }

    .community {
      width: 63%;
    }

    .newsletter {
      margin-bottom: 0;
      padding-right: rem-calc(15);
    }

    .community {
      padding-left: rem-calc(15);

      .title {
        margin: rem-calc(0 10 0 0);
      }
    }

    .blog {
      @include xy-grid;
      margin-right: rem-calc(10);
    }

    .blog,
    .social_networks {
      width: auto;
      align-items: center;
    }

    .social_networks {
      li {
        &.title {
          width: auto;
        }
      }
    }

    .newsletter {
      display: flex;
      align-items: center;
      border-right: 1px solid #d5d5d5;
    }

    .subscribe-to-newsletter {
      flex: 0 0 100%;
    }
  }
}

@include breakpoint(large up) {
  .newsletter_and_community.lazy_loaded {
    .community {
      .title {
        font: {
          size: rem-calc(16);
        }
      }

      .news {
        a {
          font: {
            size: rem-calc(16);
          }
        }
      }
    }

    .blog {
      margin-right: rem-calc(50);
    }

    .subscribe-to-newsletter {
      &__input-field {
        height: rem-calc(50);
        padding-right: rem-calc(135);
      }

      .button {
        height: rem-calc(40);
        width: rem-calc(130);
        right: rem-calc(6);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .newsletter_and_community.lazy_loaded {
    margin-bottom: rem-calc(100);

    .content {
      justify-content: center;
      padding-top: rem-calc(35);
      padding-bottom: rem-calc(50);
    }

    .section_title {
      margin-bottom: rem-calc(30);
      font: {
        size: rem-calc(36);
      }
    }

    .newsletter,
    .community {
      width: 50%;
      max-width: rem-calc(630);
    }

    .community {
      padding-bottom: rem-calc(10);

      .title {
        font: {
          size: rem-calc(18);
        }
      }

      .news {
        margin-top: rem-calc(20);
        line-height: 1;
        white-space: nowrap;
      }
    }

    .blog {
      .blog_logo {
        svg {
          width: rem-calc(110);
        }
      }
    }

    .social_networks {
      li {
        &.icon {
          height: rem-calc(35);
          width: rem-calc(35);
        }

        .svg_icon {
          height: rem-calc(20);
          width: rem-calc(20);
        }
      }
    }

    .newsletter {
      justify-content: flex-end;
    }

    .subscribe-to-newsletter {
      max-width: rem-calc(470);
    }
  }
}

@include breakpoint(xxlarge up) {
  .newsletter_and_community.lazy_loaded {
    .newsletter {
      padding-right: rem-calc(45);
    }

    .community {
      padding-left: rem-calc(45);

      .title {
        margin-right: rem-calc(15);
      }
    }

    .blog {
      .blog_logo {
        svg {
          width: rem-calc(115);
        }
      }
    }
  }
}
