/// Room labels

&.vr_games,
&.featured_room {
  box-shadow: 0 0 0 4px rgba(119, 0, 255, 1);

  .label {
    color: $white;

    &::before {
      background: #7900ff;
    }
  }
}

&.online_version {
  box-shadow: 0 0 0 4px #d909ff;

  .label {
    color: $white;

    &::before {
      background: #d909ff;
    }
  }
}

&.downloadable_game {
  box-shadow: 0 0 0 4px #ff9000;

  .label {
    color: $white;

    &::before {
      background: #ff9000;
    }
  }
}

.label {
  @include higlight_label;
  position: absolute;
  top: rem-calc(18);
  z-index: 3;
  font: {
    size: rem-calc(16);
  }

  .inner_text {
    position: relative;
  }
}

/// Room labels - Hover/focus behavior
&:hover,
&:focus {
  .label {
    opacity: 0;
  }
}

@include breakpoint(small only) {
  .label {
    top: rem-calc(3);
    left: 0;
    min-width: rem-calc(70);
    padding: rem-calc(5 5 2);
    font: {
      size: rem-calc(10);
    }
  }
}

@include breakpoint(medium up) {
  .label {
    left: rem-calc(-6);
  }
}

@include breakpoint(xlarge up) {
  .label {
    left: rem-calc(-10);
  }
}

&#room-escape-the-lost-piramid-vr-room,
&#room-huxley-vr-room,
&#room-prince-of-persia-the-dagger-of-time-vr-room,
&#room-beyond-medusas-gate-vr-room {
  &::before {
    content: '';
    position: absolute;
    right: rem-calc(20);
    z-index: 1;
    background: {
      position: center center;
      repeat: no-repeat;
    }
    background-size: cover;
  }
}

&#room-escape-the-lost-piramid-vr-room,
&#room-huxley-vr-room {
  &::before {
    top: rem-calc(13);
    height: rem-calc(82);
    width: rem-calc(127);
    background-size: cover;

    @at-root .no-webp-lossy & {
      background-image: url('components/promotions_labels/vr/winner.png');
    }

    @at-root .webp-lossy & {
      background-image: url('components/promotions_labels/vr/winner.webp');
    }
  }
}

&#room-prince-of-persia-the-dagger-of-time-vr-room,
&#room-beyond-medusas-gate-vr-room {
  &::before {
    top: rem-calc(20);
    height: rem-calc(44);
    width: rem-calc(180);

    @at-root .no-webp-lossy & {
      background-image: url('components/promotions_labels/vr/vr_awards.png');
    }

    @at-root .webp-lossy & {
      background-image: url('components/promotions_labels/vr/vr_awards.webp');
    }
  }
}

@include breakpoint(small only) {
  &#room-escape-the-lost-piramid-vr-room,
  &#room-huxley-vr-room,
  &#room-prince-of-persia-the-dagger-of-time-vr-room,
  &#room-beyond-medusas-gate-vr-room {
    &::before {
      right: rem-calc(5);
    }
  }

  &#room-escape-the-lost-piramid-vr-room,
  &#room-huxley-vr-room {
    &::before {
      top: rem-calc(5);
      height: rem-calc(32);
      width: rem-calc(50);
    }
  }

  &#room-prince-of-persia-the-dagger-of-time-vr-room,
  &#room-beyond-medusas-gate-vr-room {
    &::before {
      top: rem-calc(7);
      height: rem-calc(26);
      width: rem-calc(103);
    }
  }
}
