.promotions_banner {
  .control_button {
    background: #004db6;
    color: $white;
  }

  .content_container {
    position: relative;
    background: linear-gradient(to right, rgba(0, 77, 182, 1) 0%, rgba(255, 157, 0, 1) 100%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: rem-calc(2);

      background: {
        position: center 0;
        repeat: no-repeat;
      }

      background-size: cover;
      background-clip: content-box;

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_sm.png');
      }

      @at-root .webp-lossy & {
        background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_sm.webp');
      }
    }
  }

  .inner_container {
    @include xy-grid;
    position: relative;

    img {
      width: 100%;
    }
  }

  .gift_cards {
    @include xy-cell-base;
    width: rem-calc(168);
    margin-top: rem-calc(-15);
  }

  .description1,
  .description2 {
    @include xy-cell-base(auto);
    @include xy-grid;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .description1 {
    font: {
      size: rem-calc(18);
    }
  }

  .label {
    color: $white;

    font: {
      weight: 700;
    }
  }
}

@include breakpoint(small only) {
  .promotions_banner {
    .description2 {
      display: none;
      visibility: hidden;
    }
  }
}

@include breakpoint(medium up) {
  .promotions_banner {
    .content_container {
      &::before {
        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.webp');
        }
      }
    }

    .gift_cards {
      width: rem-calc(268);
      margin-top: rem-calc(-21);
    }

    .description1,
    .description2 {
      margin-bottom: rem-calc(20);
    }

    .description1 {
      font: {
        size: rem-calc(22);
      }
    }
  }
}

@include breakpoint(large up) {
  .promotions_banner {
    .control_button {
      background: #c818a9;
    }

    .content_container {
      background: linear-gradient(to right, rgba(200, 24, 169, 1) 0%, rgba(255, 157, 0, 1) 100%);

      &::before {
        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.webp');
        }
      }
    }

    .gift_cards {
      margin-top: rem-calc(-50);
    }

    .description1 {
      font: {
        size: rem-calc(26);
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .promotions_banner {
    .description1 {
      font: {
        size: rem-calc(34);
      }
    }

    .description2 {
      font: {
        size: rem-calc(20);
      }
    }
  }
}

@include breakpoint(1920px up) {
  .promotions_banner {
    .description1 {
      font: {
        size: rem-calc(42);
      }
    }

    .description2 {
      font: {
        size: rem-calc(24);
      }
    }
  }
}
