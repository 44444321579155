@mixin spinner($name: 'spinner') {
  @-webkit-keyframes #{$name}-spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-moz-keyframes #{$name}-spin {
    to { -moz-transform: rotate(360deg); }
  }
  @-ms-keyframes #{$name}-spin {
    to { -ms-transform: rotate(360deg); }
  }
  @keyframes #{$name}-spin {
    to { transform: rotate(360deg); }
  }

  .#{$name} {
    animation: #{$name}-spin 1.2s infinite linear;
  }
}

@include spinner($name: 'spinner');

@mixin loader_spinner($name: 'load_spinner', $color: '#fff') {
  @include spinner($name: $name);

  .#{$name} {
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    background: transparent;
    border-top: 4px solid #{$color};
    border-bottom: 4px solid #{$color};
    border-right: 4px solid;
    border-left: 4px solid;
    border-right-color: transparent;
    border-left-color: transparent;

    @include breakpoint(small only) {
      height: 1.1em;
      width: 1.1em;
      border-top: 2px solid #{$color};
      border-bottom: 2px solid #{$color};
      border-right: 2px solid;
      border-left: 2px solid;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }

  span.#{$name}, span.border-#{$name} {
    display: inline-block;
  }
}

@mixin react_loading {
  @include loader_spinner;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem-calc(30);

  .spinner,
  .load_spinner {
    width: rem-calc(40);
    height: rem-calc(40);
    margin-bottom: rem-calc(10);
    border-radius: 50%;
    border-top: 4px solid #000;
    border-bottom: 4px solid #000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

@include loader_spinner($name: 'load_spinner')
