.april_fools {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;

  background: {
    color: #68098b;
    position: center center;
    repeat: no-repeat;
  }

  white-space: normal;

  @at-root .no-webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/april_fools/bg-sm.png');
  }

  @at-root .webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/april_fools/bg-sm.webp');
  }

  .content_container {
    max-width: rem-calc(320);
    padding: rem-calc(45 0 0);
    align-items: flex-start;
    align-content: flex-start;
  }

  .description {
    margin-bottom: rem-calc(12);
    padding: 0;
    color: $white;
    line-height: 1;

    font: {
      size: rem-calc(16);
      weight: 500;
    }

    text-shadow: none;
  }

  .text {
    margin-top: rem-calc(-3);
    line-height: 1;

    font: {
      size: rem-calc(16);
      weight: 500;
    }

    span {
      font: {
        size: rem-calc(22);
        weight: 800;
      }
    }
  }

  .text-with-code {
    margin-top: rem-calc(14);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &__text {
      margin-right: rem-calc(4);
    }
  }

  .note_container {
    @include horizontal-center;
    bottom: rem-calc(8);
    width: 100%;
    max-width: rem-calc(1785);
    padding: rem-calc(0 10);
    text-align: right;
    line-height: 1;
    color: $white;

    font: {
      size: rem-calc(14);
    }
  }
}

@include breakpoint(medium up) {
  .april_fools {
    .description {
      text-align: center;
    }

    .text {
      width: 100%;
      max-width: 100%;
    }

    .note_container {
      padding: rem-calc(0 15);
    }
  }
}

@include breakpoint(xlarge up) {
  .april_fools {
    @at-root .no-webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/april_fools/bg.png');
    }

    @at-root .webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/april_fools/bg.webp');
    }

    .content_container {
      max-width: rem-calc(1785);
      padding-top: 0;
      padding: 0;
      justify-content: center;
    }

    .description {
      flex: 0 0 auto;
      width: 50%;
      max-width: rem-calc(800);
      margin: rem-calc(95 0 0);
    }

    .title-container {
      display: block;
      width: rem-calc(640);
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .text {
      font: {
        size: rem-calc(30);
      }

      span {
        font: {
          size: rem-calc(42);
        }
      }
    }

    .text-with-code {
      margin-top: rem-calc(23);

      font: {
        size: rem-calc(30);
      }

      &__text {
        margin-bottom: 0;
      }

      &__code-container {
        height: rem-calc(33);
        width: rem-calc(203);
        margin-left: rem-calc(10);
      }

      &__code {
        width: 100%;
      }
    }

    .discount-wrap {
      margin: rem-calc(25 31 0 0);
    }

    .discount-container {
      display: block;
      height: rem-calc(235);
      width: rem-calc(540);

      img {
        width: 100%;
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .april_fools {
    .title-container {
      width: rem-calc(718);
    }

    .description {
      margin-top: rem-calc(120);
    }

    .discount-wrap {
      margin-right: rem-calc(100);
    }
  }
}

@include breakpoint(1900px up) {
  .april_fools {
    .discount-wrap {
      margin-right: rem-calc(300);
    }
  }
}
