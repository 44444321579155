.unforgettable_experience {
  height: 100%;
  width: 100%;
  white-space: normal;

  @at-root .no-webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/unforgettable_experience/bg_sm.jpg');
  }

  @at-root .webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/unforgettable_experience/bg_sm.webp');
  }

  .title {
    font: {
      size: rem-calc(16);
      weight: 600;
    }
  }

  .text {
    margin-bottom: rem-calc(10);
    line-height: 1.5;

    font: {
      size: rem-calc(12);
    }
  }

  .button {
    @include orange_background_button;
    padding-bottom: rem-calc(12);
    text-shadow: none;
    text-transform: uppercase;
  }
}

@include breakpoint(medium up) {
  .unforgettable_experience {
    .content_container {
      justify-content: center;
    }

    .description {
      max-width: rem-calc(640);
      padding-top: rem-calc(90);
      padding-left: 0;
      text-align: center;
    }

    .title {
      font: {
        size: rem-calc(20);
      }
    }

    .text {
      width: 100%;
      max-width: 100%;
      margin-bottom: rem-calc(20);

      font: {
        size: rem-calc(15);
      }
    }

    .button {
      padding-bottom: rem-calc(15);

      font: {
        size: rem-calc(14);
      }
    }
  }
}

@include breakpoint(large up) {
  .unforgettable_experience {
    @at-root .no-webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/unforgettable_experience/bg.jpg');
    }

    @at-root .webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/unforgettable_experience/bg.webp');
    }

    .description {
      max-width: rem-calc(900);
    }

    .title {
      font: {
        size: rem-calc(24);
      }
    }

    .text {
      font: {
        size: rem-calc(16);
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .unforgettable_experience {
    .description {
      max-width: rem-calc(1280);
      padding-top: rem-calc(120);
    }

    .title {
      margin-bottom: rem-calc(15);

      font: {
        size: rem-calc(28);
      }
    }

    .text {
      margin-bottom: rem-calc(25);

      font: {
        size: rem-calc(18);
      }
    }
  }
}

@include breakpoint(2200px up) {
  .unforgettable_experience {
    .description {
      padding-top: rem-calc(155);
    }
  }
}
